import React from "react";

export default function Footer() {
  return (
    <>
      <div className="inline-flex flex-col items-center justify-start w-full px-8 pb-16 bg-slate-800">
        <div className="flex justify-start items-center  flex-col max-w-[1040px] ">
          <span>
            <span className="text-white text-base font-bold font-['Inter'] leading-normal">
              Disclaimer: Hi Luna is not designed to assist with crises such as
              abuse, severe mental health conditions that may cause feelings of
              suicide, harm to self, and any other medical emergencies.{" "}
            </span>
            <span className="text-white text-base font-normal font-['Inter'] leading-normal">
              Hi Luna cannot and will not offer medical or clinical advice. It
              can only suggest that users seek advanced and professional medical
              help. Please reach out to your country-specific suicide hotline in
              case of an emergency. You must be at least 18 years of age to use
              Hi Luna.
              <br />
              <br />
            </span>
          </span>
        </div>
      </div>
      <div className="inline-flex flex-col items-center justify-start w-full gap-5 pb-5 bg-slate-800">
        <div className="self-stretch h-[0px] border border-slate-500"></div>
        <div className="inline-flex flex-wrap items-start justify-center gap-5 px-8 ">
          <a
            className="text-white text-[13px] font-normal font-['Inter']"
            href="/terms-of-service"
          >
            Terms of Service
          </a>
          <a
            className="text-white text-[13px] font-normal font-['Inter'] "
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <div className="text-white text-[13px] font-normal font-['Inter']">
            © 2023 Hi Luna
          </div>
        </div>
      </div>
    </>
  );
}
