import React from "react";
import LunaLogo from "../assets/luna-logo-white.png";

export default function Header() {
  return (
    <div className="w-full px-8 md:px-[200px] py-4 bg-blue-800 justify-between items-center inline-flex">
      <div className="relative h-8">
        <a href="/">
          <img className="w-[118px] h-8" src={LunaLogo} alt="logo" />
        </a>
      </div>
    </div>
  );
}
