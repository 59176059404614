import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Tos() {
  return (
    <div className="inline-flex flex-col items-center justify-start w-full bg-white">
      <Header />
      <div className=" px-8 md:px-[200px] py-16 flex-col justify-start items-center gap-8 flex">
        <div className="flex flex-col items-center self-stretch justify-center gap-4 pb-10 border-b border-blue-300">
          <div className="text-blue-800 text-5xl font-bold font-['Inter'] text-center">
            Privacy Policy
          </div>
        </div>
        <div className="flex flex-col items-center self-stretch justify-start gap-8 px-8 md:px-20">
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              At Multivac Labs, llc, (“we,” “us,” or “our”) makers of the Hi
              Luna app (“app”), one of our main priorities is the privacy of our
              visitors. This Privacy Policy document contains types of
              information that is collected and recorded by the app and how we
              use it. If you have additional questions or require more
              information about our Privacy Policy, do not hesitate to contact
              us. This Privacy Policy applies only to our online activities and
              is valid for users of our app with regards to the information that
              they shared and/or collected on our app. This policy is not
              applicable to any information collected offline or via channels
              other than the app.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            Consent
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              By using our app, you hereby consent to our Privacy Policy and
              agree to its terms.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            Information we collect
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              When you operate the app, we might receive the following
              information and you authorize us to receive this information.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Mood tracking information as well as notes Conversation logs A
              pseudonymous nickname Logs of your interactions with the app and
              this website
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Additional information you are asked to provide, and the reasons
              why you are asked to provide it, will be made clear to you at the
              point we ask you to provide additional information.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            How we use your information
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              We use the information we collect to in various ways, including
              to:
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Additional information you are asked to provide, and the reasons
              why you are asked to provide it, will be made clear to you at the
              point we ask you to provide additional information.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Additional information you are asked to provide, and the reasons
              why you are asked to provide it, will be made clear to you at the
              point we ask you to provide additional information.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            Cookies and Web Beacons
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Like any other website, this website uses 'cookies'. These cookies
              are used to store information including visitors' preferences, and
              the pages on the website that the visitor accessed or visited. The
              information is used to optimize the users' experience by
              customizing our web page content based on visitors' browser type
              and/or other information.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Under the CCPA, among other rights, California consumers have the
              right to:
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            GDPR Data Protection Rights
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            Children's Information
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              The app does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website or
              app, we strongly encourage you to contact us immediately and we
              will do our best efforts to promptly remove such information from
              our records.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              Initial Effective Date: Jan 1, 2024 (GMT) Version: 0.0.1
            </div>
          </div>
        </div>
      </div>
      <div className=" h-[60px] w-full flex bg-slate-800 justify-center items-center" />
      <Footer />
    </div>
  );
}
