import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import thanks from "../assets/thanks.svg";

export default function Thanks() {
  return (
    <div className="inline-flex flex-col items-center justify-start w-full bg-white">
      <Header />
      <div className=" px-8 md:px-[200px] py-16 flex-col justify-start items-center gap-8 flex">
        <img src={thanks} className="h-[264px] w-[262px]" alt="thanks" />
        <div className="flex flex-col items-center self-stretch justify-center gap-4 pb-10 border-b border-blue-300">
          <div className="text-blue-800 text-5xl font-bold font-['Inter']">
            Thanks!
          </div>
        </div>
        <div className="flex flex-col items-center self-stretch justify-start gap-8 px-8 md:px-20">
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            We'll be in touch soon!
          </div>
        </div>
      </div>
      <div className=" h-[60px] w-full flex bg-slate-800 justify-center items-center" />
      <Footer />
    </div>
  );
}
