import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Tos() {
  return (
    <div className="inline-flex flex-col items-center justify-start w-full bg-white">
      <Header />
      <div className=" px-8 md:px-[200px] py-16 flex-col justify-start items-center gap-8 flex">
        <div className="flex flex-col items-center self-stretch justify-center gap-4 pb-10 border-b border-blue-300">
          <div className="text-blue-800 text-5xl font-bold font-['Inter'] text-center">
            Terms of Service
          </div>
        </div>
        <div className="flex flex-col items-center self-stretch justify-start gap-8 px-8 md:px-20">
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              Welcome to Hi Luna, the mobile and online service of Multivac
              Labs, LLC (hereinafter referred to as "Hi Luna", or "We", or "Us"
              or "Our"). This Terms of Service applies to all visitors, data
              subject, and others who access the Service. This document is a
              legally binding agreement between You and Multivac Labs, LLC, a
              private limited liability company registered in New York, United
              States with registered office at 418 Broadway, Ste N, Albany
              NY,12207 United States in the USA. The domain name
              <a href="/">www.hiluna-app.com</a> and all associated subdomains
              (hereinafter referred to as "Website") and Hi Luna mobile
              application, web-based and online applications ("Mobile Software"
              or "Hi Luna App") is owned and managed by Multivac Labs, LLC .
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              You may use our Apps to access one or more of our services and
              offerings (collectively the "Service(s)" or “Hi Luna Service”).
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              <ol>
                <li>1. AI chatbot (“AI Coach”).</li>
                <li>2. Digital selfcare tool-packs.</li>
                <li>
                  3.Services offered over WhatsApp (available in select
                  geographies only).
                </li>
                <li>4. Services purchased from our website and web pages.</li>
                <li>5. Digital front door or e-triage.</li>
                <li>
                  6. Multi-lingual offerings (available in select geographies
                  only).
                </li>
              </ol>
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              We may also provide these and additional services on behalf of
              Your Institution (“Institutional Services”). An Institution could
              be an enterprise, university, hospital, research institution and
              other public or private organizations. Institutional Services may
              involve processing information on behalf of the Institution. Where
              applicable, you must agree to the Terms of Services and Privacy
              Policies of both Hi Luna and your Institution in order to proceed
              with using the Institutional Service.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              By accessing or using the Hi Luna App and Service, You signify
              that You have read, understood, and agree to be bound by this
              terms of service and to the collection and use of Your Information
              as set forth in the Privacy Policy and terms of any other policies
              of Hi Luna as may be applicable to You whether or not You are a
              registered User of Our Service. These Terms of service, Privacy
              Policy and any other terms and conditions as may be applicable
              shall hereinafter collectively be referred to as “Agreement” or
              “Contract”. For further Information regarding the use of Your data
              by Hi Luna, please read Our Privacy Policy. Where not specifically
              called out, use of uppercase / lowercase would carry the same
              meaning in this document.
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal mb-4">
              Initial Effective Date: Jan 1, 2024 (GMT) Version: 0.0.1
            </div>
          </div>
          <div className="self-stretch text-black text-xl font-bold font-['Inter'] leading-normal">
            Important Notice
          </div>
          <div className="self-stretch">
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              <ol>
                <li>
                  1. If You are having suicidal thoughts or planning to act on
                  suicidal thoughts, or if You feel that You may be a danger to
                  Yourself or to others, or if You otherwise have any medical or
                  mental health emergency or severe mental health condition, or
                  if You are in a crisis or trauma or abuse, please discontinue
                  use of the Service immediately and call the relevant emergency
                  number in Your country or the approved helplines provided by
                  Your Institution.
                </li>
                <li>
                  2. Hi Luna is not to be used by people under 13 years. Hi Luna
                  does not take responsibility for any misrepresentation of age
                  and use.
                </li>
                <li>
                  3. Your interaction with the AI Coach is with an AI system and
                  not a human. The AI Coach’s intended use is to encourage
                  mental well-being in a self-help and self-monitoring context.
                  It is not intended for providing diagnosis, treatment or cure
                  of a condition or disease. The AI Coach cannot and will not
                  offer advice on issues it does not recognize.
                </li>
                <li>
                  4. Hi Luna Coach cannot and will not offer medical or clinical
                  advice. In case You mention the need for such advice, they
                  will suggest that You seek advanced (medical) help.
                </li>
                <li>
                  5. Hi Luna App is designed to offer general mental health
                  advice and support and cannot offer condition specific advice
                  for complex medical conditions such as complex long term
                  illness, cancer, infertility or genetic disorders among
                  others.
                </li>
                <li>
                  6. Please first consult a healthcare professional before using
                  the App, if You have any medical conditions including but not
                  limited to Pregnancy, Sleep Apnea, Restless Leg Syndrome
                  (RLS), Seizures or Epilepsy, hallucinations or nightmares,
                  sleep walking, excessive daytime sleepiness, dizziness or
                  tremors. The App may not be right for You if You are; a person
                  under 18 years, someone with severe mental or physical
                  condition or disorder, a shift worker, someone who regularly
                  keeps changing sleep schedules or someone who sleeps at times
                  that are out of sync with Your body’s natural rhythm.
                </li>
                <li>
                  7. We take all reasonable care to keep Your conversations
                  private and secure.
                </li>
                <li>
                  8. For safety and security reasons, We strongly recommend that
                  You keep Your conversations with Hi Luna App private. We
                  strongly recommend that You set automatic updates of the Hi
                  LunaApp in the application manager settings of Your mobile
                  device; to get the latest Hi Luna App-based features and
                  fixes.
                </li>
                <li>
                  9. Your submitted Data will be transferred, stored and
                  processed in Our secure servers hosted by our cloud service
                  providers. You understand and agree that We may transfer,
                  store and process minimal data outside Your region to enable
                  us to provide Our services. These could be to regions where
                  data protection laws may be less stringent than those in Your
                  country. Such data handling will be based on Our Privacy
                  Policy.
                </li>
                <li>
                  10. The App and its Services cannot and should not be used by
                  a third-party for Independent Research and Clinical Studies
                  without the explicit permission of Hi luna.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className=" h-[60px] w-full flex bg-slate-800 justify-center items-center" />
      <Footer />
    </div>
  );
}
