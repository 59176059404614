export const topicArray = [
  {
    name: "Just want to talk",
    color: "#49B3BF",

    value: "just_want_to_talk",
    text: "I just want to talk",
  },
  {
    name: "Confidence",
    color: "#1D7480",

    value: "get_confident",
    text: "Help me be more confident",
  },
  {
    name: "Better relationships",
    color: "#4472A9",
    value: "better_relationships",
    text: "Help me improve my relationships",
  },
  {
    name: "Make life changes",
    color: "#3149AA",

    value: "make_changes",
    text: "Help me make life changes",
  },
  {
    name: "Productivity",
    color: "#623793",

    value: "be_productive",
    text: "Help me be more productive",
  },
  {
    name: "Better habits",
    color: "#755FAC",
    value: "build_better_habits",
    text: "Help me build better habits",
  },
  {
    name: "Self-acceptance",
    color: "#955597",
    value: "self_acceptance",
    text: "I want to work on self-acceptance",
  },
  {
    name: "Emotional intelligence",
    color: "#AC7476",
    value: "emotional_intelligence",
    text: "Help me understand and manage my emotions",
  },
  {
    name: "Health",
    color: "#A74753",
    value: "get_healthy",
    text: "Help me get healthy",
  },
  {
    name: "Social intelligence",
    color: "#B7754E",
    value: "get_social",
    text: "Help me be more social",
  },
  // {
  //   name: "Business",
  //   color: "#B4A143",
  //   value: "business",
  //   text: "Help me with business",
  // },
  // {
  //   name: "Learning",
  //   color: "#D2D0C8",
  //   value: "learning",
  //   text: "Help me learn something",
  // },
  // {
  //   name: "Improve my dating life",
  //   color: "#49B3BF",
  //   color: "#84A172",
  //   value: "improve_my_dating_life",
  //   text: "Help me improve my dating life",
  // },
  // {
  //   name: "Find meaning",

  // color: "#B4A143",
  //   value: "find_meaning",
  //   text: "Help me find meaning",
  // },
];
