import topImage from "../assets/top-section-art.svg";
import bottomCard from "../assets/bottom-card.svg";
import firstStepArt from "../assets/first-step-art.png";
import motivationArt from "../assets/motivation-art.png";
import assistanceArt from "../assets/assistance-art.png";
import phoneArtCheckIn from "../assets/phone-art-checkin.png";
import phoneArtMeditation from "../assets/phone-art-meditation.png";
import anonymousArt from "../assets/anonymous-art.png";
import phoneArtChat from "../assets/phone-art-chat.png";
import star from "../assets/yellow-star.svg";
import doubleQuotes from "../assets/double-quotes.svg";
import { topicArray } from "../topicData";
import Footer from "../Components/Footer";
import LunaLogo from "../assets/luna-logo.svg";
// import warning from "../assets/warning.svg";
import { useState, useEffect } from "react";
import { AppStoreButton } from "react-mobile-app-button";

const iOSUrl = "https://apps.apple.com/us/app/hi-luna/id6498314955";
function HomePage() {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);

  // function to check for valid email
  function checkEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // function to check for valid email
  useEffect(() => {
    if (email === "") {
      setValidEmail(true);
    } else {
      setValidEmail(checkEmail(email));
    }
  }, [email]);

  //function to send email and navigate to thanks page
  function sendEmail() {
    if (validEmail) {
      fetch(
        "https://therapygpt-server.onrender.com/alpha_signup?email=" + email
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          window.location.href = "/thanks";
        });
    }
  }

  return (
    <div className="flex flex-col items-center justify-start">
      <div
        className="w-full h-[100vh] bg-cover bg-no-repeat bg-bottom bg-[#9EC9FF]"
        style={{ backgroundImage: `url(${topImage})` }}
        alt="logo"
      >
        <div className="w-full px-8 md:px-[200px] py-4 justify-between items-center inline-flex">
          <div className="relative h-8">
            <a href="/">
              <img className="w-[118px] h-8" src={LunaLogo} alt="logo" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full gap-10">
          <div className="flex flex-col items-center justify-center flex-[2]">
            <div className="max-w-[600px] text-center text-black text-base md:text-[40px] font-bold font-['Inter'] mb-[20px]">
              Start your wellness journey
            </div>
            <div className=" max-w-[600px] text-center mb-[40px]">
              <span className="text-black text-base font-normal font-['Inter'] leading-normal">
                Say{" "}
              </span>
              <span className="text-black text-base font-bold font-['Inter'] leading-normal">
                Hi
              </span>
              <span className="text-black text-base font-normal font-['Inter'] leading-normal">
                {" "}
                to{" "}
              </span>
              <span className="text-black text-base font-bold font-['Inter'] leading-normal">
                Luna
              </span>
              <span className="text-black text-base font-normal font-['Inter'] leading-normal">
                , an AI-powered therapist for anonymous support whenever,
                wherever.{" "}
              </span>
            </div>
            <AppStoreButton
              theme="dark"
              url={iOSUrl}
              linkProps={{ title: "iOS Store Button" }}
            />
          </div>
          <div className="flex flex-[1]" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-4 mt-16">
        <div className="text-black text-2xl font-bold font-['Inter']">
          Why use Hi Luna?
        </div>
      </div>
      {/* 3 Pics */}
      <div className="flex justify-center items-start gap-[64px] mb-[120px] mt-16 max-w-[1040] flex-wrap">
        <div className="flex flex-col items-center justify-center gap-4 max-w-[312px]">
          <img className="w-[160px] h-[160px]" src={firstStepArt} alt="logo" />
          <div className="text-black text-xl font-bold font-['Inter']">
            An encouraging first step
          </div>
          <div className="text-black text-base font-normal font-['Inter'] leading-normal text-center">
            AI can be a comforting and effective first step towards improving
            your mental and emotional well-being.
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-4 max-w-[312px]">
          <img className="w-[160px] h-[160px]" src={motivationArt} alt="logo" />
          <div className="text-black text-xl font-bold font-['Inter']">
            Stay motivated{" "}
          </div>
          <div className="text-black text-base font-normal font-['Inter'] leading-normal  text-center">
            Hi Luna combines self-care tools with a gamified feel to encourage
            you to reflect daily.
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-4 max-w-[312px]">
          <img className="w-[160px] h-[160px]" src={assistanceArt} alt="logo" />
          <div className="text-black text-xl font-bold font-['Inter']">
            24/7 Private assistance{" "}
          </div>
          <div className="text-black text-base font-normal font-['Inter'] leading-normal  text-center">
            No matter where or when, Luna will be available when you need to
            talk.
          </div>
        </div>
      </div>

      {/* Anonymous box */}
      <div className="flex items-center mx-[32px]">
        <div className="flex  max-w-[1040px] mx-auto flex-wrap items-center justify-center gap-4 px-[34px] md:px-[88px] bg-[#E7E9F2] rounded-[30px] mb-[120px]">
          <div className="flex-col py-[40px] max-w-[400px]">
            <div className="text-black text-2xl font-bold font-['Inter'] ">
              Open up Anonymously
            </div>
            <div className="text-black text-base font-normal font-['Inter'] leading-normal">
              Your personal information stays with you. No registration is
              required to use Hi Luna, and all conversations are secure and
              confidential.
            </div>
          </div>
          <div className="pt-[40px] w-[294px]">
            <img
              src={anonymousArt}
              className="w-[294px] h-[360px]"
              alt="anonymous"
            />
          </div>
        </div>
      </div>
      {/* Phone pics */}
      <div className="pb-[120px] justify-between items-center inline-flex flex-wrap px-[32px] gap-16  max-w-[1040px] ">
        <img
          src={phoneArtCheckIn}
          alt="check-in"
          className="w-[440px] aspect-auto"
        />
        <div className="inline-flex flex-col items-center justify-start gap-4 grow shrink basis-0">
          <div className="self-stretch text-slate-500 text-base font-bold font-['Inter']">
            How it works
          </div>
          <div className="self-stretch text-black text-2xl font-bold font-['Inter']">
            Simple daily check ins
          </div>
          <div className="self-stretch text-black text-base font-normal font-['Inter'] leading-normal">
            No two days are alike. Hi Luna makes it easy to track your mood,
            emotions, and events throughout the day.{" "}
          </div>
        </div>
      </div>
      <div className="flex-wrap-reverse pb-[120px] justify-between items-center inline-flex px-[32px] gap-16  max-w-[1040px] ">
        <div className="inline-flex flex-col items-center justify-start gap-4 grow shrink basis-0">
          <div className="self-stretch text-slate-500 text-base font-bold font-['Inter']">
            How it works
          </div>
          <div className="self-stretch text-black text-2xl font-bold font-['Inter']">
            Discover peace with meditation{" "}
          </div>
          <div className="self-stretch text-black text-base font-normal font-['Inter'] leading-normal">
            Whether you’re new to meditation or come with experience, access a
            variety of techniques with personalized daily recommendations.
          </div>
        </div>
        <img
          src={phoneArtMeditation}
          alt="check-in"
          className="w-[440px] aspect-auto"
        />
      </div>
      <div className="flex-wrap pb-[120px] justify-between items-center inline-flex px-[32px] gap-16  max-w-[1040px] ">
        <img
          src={phoneArtChat}
          alt="check-in"
          className="w-[440px] aspect-auto"
        />

        <div className="inline-flex flex-col items-center justify-start gap-4 grow shrink basis-0">
          <div className="self-stretch text-slate-500 text-base font-bold font-['Inter']">
            How it works
          </div>
          <div className="self-stretch text-black text-2xl font-bold font-['Inter']">
            Chat about anything{" "}
          </div>
          <div className="self-stretch text-black text-base font-normal font-['Inter'] leading-normal">
            Talk to Luna about anything anytime, whether you need guidance on
            serious matters or simply need a friendly conversation.
          </div>
        </div>
      </div>
      {/* Topics */}
      <div className="flex flex-col items-center justify-start gap-4 mb-16">
        <div className="text-black text-2xl font-bold font-['Inter'] text-center">
          Get customized assistance based on subject matter
        </div>
        <div className=" text-black text-base  text-center font-normal font-['Inter'] leading-normal">
          Luna's AI engages differently based on your chosen topic of
          conversation.
        </div>
      </div>
      <div className="flex justify-center w-full overflow-clip">
        <div className="flex gap-[30px] flex-wrap  justify-center mb-[64px] max-w-[940px] min-w-[940px] overflow-clip">
          {topicArray.map((topic) => (
            <div
              key={topic.text}
              style={{ backgroundColor: topic.color }}
              className="flex flex-col items-center justify-center py-[10px] px-[30px] rounded-[99px] "
            >
              <div className="text-white text-xl  font-['Inter'] ">
                {topic.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="py-[120px] flex-col justify-start items-center gap-16 inline-flex">
        <div className="flex flex-col items-center justify-start gap-4">
          <div className="self-stretch text-black text-2xl font-bold font-['Inter']">
            User Testimonials
          </div>
        </div>
        <div className="inline-flex flex-wrap items-start self-stretch justify-center gap-4">
          <Testimonial
            initial={"C"}
            name={"Caroline S."}
            text={
              "I’ve been using Hi Luna for the past 2 months and I have learned so many new activities to keep my headspace positive."
            }
          />
          <Testimonial
            initial={"C"}
            name={"Franklin F."}
            text={
              "I can’t believe it took me this long to seek help, but I’m glad I did it. "
            }
          />
          <Testimonial
            initial={"G"}
            name={"Georgia A."}
            text={
              "Luna’s AI really does make a difference in my mental health. "
            }
          />
        </div>
      </div>
      <div className=" py-[120px] w-full flex bg-slate-800 justify-center items-center">
        <div
          className="max-w-[1040px] w-full h-[572px] flex flex-col justify-center items-center bg-cover bg-no-repeat bg-bottom mx-[32px] rounded-[30px]"
          style={{ backgroundImage: `url(${bottomCard})` }}
        >
          <div className=" text-black text-[32px] text-center font-bold font-['Inter'] mb-[32px]">
            Take the first step toward self-care
          </div>
          <AppStoreButton
            theme="dark"
            url={iOSUrl}
            linkProps={{ title: "iOS Store Button" }}
          />
          {/* <>
            <div className="text-center text-blue-800 text-xl font-bold font-['Inter'] mb-[40px]">
              Sign up for early access
            </div>
            <div className="max-w-[480px] px-[32px] w-full flex-col justify-center items-center gap-3 inline-flex">
              <input
                placeholder="Email"
                className="self-stretch h-14 pl-6 bg-white rounded-[99px] justify-start items-center gap-2.5 inline-flex"
                onChange={(e) => setEmail(e.target.value)}
              />

              <button
                className="h-14 px-10 py-3 cursor-pointer bg-blue-800 rounded-[99px] justify-center items-center gap-2 inline-flex"
                onClick={sendEmail}
                disabled={!validEmail}
              >
                <div className="text-white text-xl font-bold font-['Inter']">
                  Get Access
                </div>
              </button>
              {!validEmail && (
                <div className="px-2 py-1 bg-white rounded-[40px] justify-center items-center gap-2 inline-flex">
                  <img
                    src={warning}
                    className="relative w-5 h-5"
                    alt="warning"
                  />
                  <div className="text-center text-red-400 text-base font-normal font-['Inter']">
                    Invalid Email
                  </div>
                </div>
              )}
              <div className="text-center text-black text-base font-normal font-['Inter']">
                Become an alpha tester! We’ll send a link to your inbox.
              </div>
            </div>
          </> */}
        </div>
      </div>
      {/* <div className="pb-16 w-full bg-slate-800 flex-col justify-start items-center gap-[62px] inline-flex">
        <div className="inline-flex flex-wrap items-start self-stretch justify-between">
          <div className="inline-flex flex-col items-center justify-start gap-1">
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-bold font-['Inter'] leading-loose">
                Citation:
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-normal font-['Inter']">
                Disclaimer: Hi Luna is not designed to assist with crises such
                as abuse,{" "}
              </div>
            </div>
          </div>
          <div className="inline-flex flex-col items-center justify-start gap-1">
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-bold font-['Inter'] leading-loose">
                Citation:
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-normal font-['Inter']">
                Disclaimer: Hi Luna is not designed to assist with crises such
                as abuse,{" "}
              </div>
            </div>
          </div>
          <div className="inline-flex flex-col items-center justify-start gap-1">
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-bold font-['Inter'] leading-loose">
                Citation:
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-2.5 flex">
              <div className="w-[303px] text-slate-500 text-base font-normal font-['Inter']">
                Disclaimer: Hi Luna is not designed to assist with crises such
                as abuse,{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default HomePage;

function Testimonial({ initial, name, text }) {
  return (
    <div className="w-80 h-[332px] p-8 bg-slate-200 rounded-[30px] flex-col justify-between items-start inline-flex ">
      <div className="self-stretch h-[172px] flex-col justify-start items-start gap-5 flex">
        <img className="relative w-10 h-10" src={doubleQuotes} alt="quote" />
        <div className="self-stretch text-black text-base font-normal font-['Inter'] leading-7">
          {text}
        </div>
      </div>

      <div className="flex items-center self-stretch justify-start gap-2">
        <div className="w-14 h-14 leading-[56px] bg-blue-800 rounded-[99px] flex-col justify-center items-center gap-2.5 inline-flex text-white text-xl font-bold font-['Inter']">
          {initial}
        </div>
        <div className="inline-flex flex-col items-start justify-start gap-1">
          <div className=" text-black text-base font-bold font-['Inter'] leading-7">
            {name}
          </div>
          <div className="inline-flex items-start justify-start">
            <img className="relative w-6 h-6" src={star} alt="star" />
            <img className="relative w-6 h-6" src={star} alt="star" />
            <img className="relative w-6 h-6" src={star} alt="star" />
            <img className="relative w-6 h-6" src={star} alt="star" />
            <img className="relative w-6 h-6" src={star} alt="star" />
          </div>
        </div>
      </div>
    </div>
  );
}
